
export const CancelOrder =`
mutation CancelOrder($orderId: String) {
  cancelOrder(orderId: $orderId) {
    orderId
    refundStatus
    refundAmount
    refunxTxnId
    message
    orderStatus
  }
}`
export const AddLandingPageDisplay =`
mutation AddLandingPageDisplay($landingPageInput: LandingPageInput) {
  addLandingPageDisplay(landingPageInput: $landingPageInput)
}`

// export const CancelOrder =`
// mutation CancelOrder($orderId: String) {
//   cancelOrder(orderId: $orderId) {
//     orderId
//     refundStatus
//     refundAmount
//     refunxTxnId
//     message
//     orderStatus
//   }
// }`
export const AdminAssignOrdersToRiders =`mutation AdminAssignOrdersToRiders($assignOrdersInput: AssignOrdersInput) {
  adminAssignOrdersToRiders(assignOrdersInput: $assignOrdersInput) {
    lsId
    csId
    riders
    orders
    expansionRatio
    thresholdFactor
    assigned {
      riderEmail
      totalWeight
      totalOrders
      orders {
        _id
        customerId
        customerPhone
        customerName
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        deliveryDate
        latitude
        longitude
        orderAmount
      }
    }
    unassigned {
      orders {
        _id
        customerId
        customerPhone
        customerName
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        deliveryDate
        latitude
        longitude
        orderAmount
      }
    }
  }
}`
export const UpdateSpecialOffer =`
mutation UpdateSpecialOffer($updateSpecialOfferId: String!, $offerInput: OfferInput) {
  updateSpecialOffer(id: $updateSpecialOfferId, offerInput: $offerInput) {
    _id
    name
    action
    image
    displayStyle
    isActive
    updatedAt
    storeId
  }
}`
export const AddSpecialOffer =`
mutation AddSpecialOffer($offerInput: OfferInput) {
  addSpecialOffer(offerInput: $offerInput) {
    _id
    name
    action
    image
    displayStyle
    isActive
    updatedAt
    storeId
  }
}`
export const UpgradeAllCustomerToPremium =`
mutation UpgradeAllCustomerToPremium($expiryDate: String!) {
  upgradeAllCustomerToPremium(expiryDate: $expiryDate) {
    upgraded {
      _id
      phone
      name
    }
    count
  }
}`
export const refreshQuotationDataFromExternalSource=`
mutation Mutation {
  refreshQuotationDataFromExternalSource
}`

export const refreshTaxUpdatesFromExternalSource = `
mutation Mutation {
 refreshTaxUpdatesFromExternalSource
}`

export const deleteCustomerDeliverySlot =`
mutation Mutation {
  deleteCustomerDeliverySlot
}`
export const AssignNewCustomerDeliverySlot =`
mutation AssignNewCustomerDeliverySlot($slotId: String!, $date: String!, $reason: String!, $orderId: String) {
  assignNewCustomerDeliverySlot(slotId: $slotId, date: $date, reason: $reason, orderId: $orderId) {
    _id
    items {
      productId
      skuId
      name
      description
      image
      quantity
      mrp
      prices {
        customerType
        sellingPrice
      }
      status
      packingChecked
      billingChecked
    }
    customerType
    customer {
      _id
      name
      phone
      email
      gender
      nativePlace
      customerType
      isActive
      jwtToken
      refreshToken
      addresses {
        _id
        deliveryAddress
        details
        label
        selected
        isActive
      }
      status
      permittedPaymentOptions {
        method
        label
      }
      selectedPaymentMethod
      cart {
        items {
          productId
          skuId
          name
          description
          image
          quantity
          mrp
          prices {
            customerType
            sellingPrice
          }
          status
          packingChecked
          billingChecked
        }
        deliveryAddress {
          _id
          location {
            coordinates
          }
          deliveryAddress
          details
          label
          selected
          isActive
        }
        billDetails {
          itemsTotal
          deliveryCharge
          grandTotal
          totalSaving
          handlingCharge
          itemSavings
          itemsMrp
          chargesWaived
          couponDiscount
          walletInfo {
            applied
            amount
            message
          }
          couponInfo {
            applied
            amount
            code
            message
            cancellationApplied
          }
          nestInfo {
            applied
            amount
            message
            couponRefundAmount
            couponRefundAccomodated
          }
          cancelledInfo {
            nestInfo {
              applied
              amount
              message
              couponCancellationAmount
              couponCancellationAccomodated
            }
          }
        }
      }
      GST
    }
    paymentStatus
    paymentMethod
    paidAmount
    orderAmount
    orderDate
    deliveryDate
    billDetails {
      itemsTotal
      deliveryCharge
      grandTotal
      totalSaving
      handlingCharge
      itemSavings
      itemsMrp
      chargesWaived
      couponDiscount
      walletInfo {
        applied
        amount
        message
      }
      couponInfo {
        applied
        amount
        code
        message
        cancellationApplied
      }
      nestInfo {
        applied
        amount
        message
        couponRefundAmount
        couponRefundAccomodated
      }
      cancelledInfo {
        nestInfo {
          applied
          amount
          message
          couponCancellationAmount
          couponCancellationAccomodated
        }
      }
    }
    deliveryAddress {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    isOrderModified
    refundStatus
    orderState {
      status
      workerStatus
      ppWorkerAssigned
      ppWorkerAssignedEmail
      billingWorkerAssigned
      billingWorkerAssignedEmail
      riderAssigned
      riderAssignedEmail
    }
    invoiceId
    deliveredImage
    customerDeliverySlot {
      name
      leadTime
      timeSlot {
        start
        end
        _id
      }
    }
    deliveryModifications {
      from
      to
      by
      on
      reason
    }
  }
}`
export const AddCustomerDeliverySlot =`
mutation AddCustomerDeliverySlot($start: String!, $end: String!, $leadTime: Float) {
  addCustomerDeliverySlot(start: $start, end: $end, leadTime: $leadTime)
}`
export const refreshAllDataFromExternalSource =`
mutation Mutation {
  refreshAllDataFromExternalSource
}`
export const  SetOrderItemChecked =`
mutation SetOrderItemChecked($orderId: String, $itemId: String, $checked: Boolean) {
  setOrderItemChecked(orderId: $orderId, itemId: $itemId, checked: $checked)
}`
export const AddCreditToNestCash=`
mutation AddCreditToNestCash($phone: String!, $amount: Float!, $expiry: String!, $applyPercent: Float!, $reason: String!) {
  addCreditToNestCash(phone: $phone, amount: $amount, expiry: $expiry, applyPercent: $applyPercent, reason: $reason) {
    applied
    message
    phone
  }
}`
export const AddCreditToCustomerWalletForOrder =`
mutation AddCreditToCustomerWalletForOrder($orderId: String!, $amount: Float!, $description: String!) {
  addCreditToCustomerWalletForOrder(orderId: $orderId, amount: $amount, description: $description)
}`
export const DeleteCoupon =`
mutation DeleteCoupon($code: String!) {
  deleteCoupon(code: $code)
}`
export const AddCoupon =`mutation AddCoupon($couponInput: CouponInput) {
  addCoupon(couponInput: $couponInput) {
    name
    description
    storeId
    type
    code
    start
    end
    applyAmount
    orderAmount
  }
}`
export const UpdateCoupon =`
mutation UpdateCoupon($code: String!, $start: DateTime, $end: DateTime, $applyAmount: Float) {
  updateCoupon(code: $code, start: $start, end: $end, applyAmount: $applyAmount) {
    name
    storeId
    type
    code
    start
    end
    applyAmount
  }
}`
export const CreateCustomerReferrals = `
mutation CreateCustomerReferrals($referralInput: ReferralInput) {
  createCustomerReferrals(referralInput: $referralInput) {
    _id
    storeId
    type
    isActive
    code
    expiry
    refereeDiscountPecentage
    referrerAmount
    referrerPercent
    referrerEventName
    referredEventRegisterdCount
    referredEventAppliedCount
    referredEventRedeemedCount
  }
}`

export const UpdateCustomerReferrals = `
mutation UpdateCustomerReferrals($referralInput: ReferralInput) {
  updateCustomerReferrals(referralInput: $referralInput) {
    _id
    storeId
    type
    isActive
    code
    expiry
    refereeDiscountPecentage
    referrerAmount
    referrerPercent
    referrerEventName
    referredEventRegisterdCount
    referredEventAppliedCount
    referredEventRedeemedCount
  }
}`
export const CreateEventReferrals = `
mutation CreateEventReferrals($eventReferralInput: EventReferralInput) {
  createEventReferrals(eventReferralInput: $eventReferralInput) {
    _id
    storeId
    type
    isActive
    code
    expiry
    refereeDiscountPecentage
    referrerAmount
    referrerPercent
    referrerEventName
    referredEventRegisterdCount
    referredEventAppliedCount
    referredEventRedeemedCount
  }
}`
export const UpdateEventReferrals = `
mutation UpdateEventReferrals($eventReferralInput: EventReferralInput) {
  updateEventReferrals(eventReferralInput: $eventReferralInput) {
    _id
    storeId
    type
    isActive
    code
    expiry
    refereeDiscountPecentage
    referrerAmount
    referrerPercent
    referrerEventName
    referredEventRegisterdCount
    referredEventAppliedCount
    referredEventRedeemedCount
  }
}`
export const DeleteEventReferrals = `
mutation DeleteEventReferrals($code: String) {
  deleteEventReferrals(code: $code)
}`

export const UpdateAdminWebPushToken = `
mutation UpdateWorkerWebPushNotification($token: String!) {
  updateWorkerWebPushNotification(token: $token)
}`

export const UpdateCustomerType = `
mutation UpdateCustomerType($customerId: String!, $type: Int!) {
  updateCustomerType(customerId: $customerId, type: $type)
}`
export const UpdateCustomerTypeCharges = `
mutation UpdateCustomerTypeCharges($type: Int!, $charges: ChargesInput!) {
  updateCustomerTypeCharges(type: $type, charges: $charges) {
    name
    _id
    customerType
    description
    charges {
      handling {
        waiverAmount
        rate
      }
      delivery {
        waiverAmount
        rate
      }
    }
    default
  }
}`
export const AddNewCustomerType = `
mutation AddNewCustomerType($name: String!, $description: String!, $customerType: Int!) {
  addNewCustomerType(name: $name, description: $description, customerType: $customerType) {
    name
    _id
    description
    charges {
      handling {
        minimumAmount
        rate
      }
      delivery {
        minimumAmount
        rate
      }
    }
  }
}`

export const RetryRefund = `
mutation RetryRefund($orderId: String!) {
  retryRefund(orderId: $orderId) {
    orderId
    refundTxnId
    onlineTxnId
    refundAmount
    status 
  }
}`
export const ProcessOrderRefund = `
mutation ProcessOrderRefund($orderId: String!, $refundAmount: Float!, $reason: String!, $isOrderCancelled: Boolean!) {
  processOrderRefund(orderId: $orderId, refundAmount: $refundAmount, reason: $reason, isOrderCancelled: $isOrderCancelled) {
    orderId
    refundTxnId
    onlineTxnId
    refundAmount
    status
  }
}`

export const UpdatePremiumCustomerCronTimeSlot = `
mutation UpdatePremiumCustomerCronTimeSlot($start: String!, $end: String!) {
  updatePremiumCustomerCronTimeSlot(start: $start, end: $end) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
   
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
    premiumCustomerConfig {
      purchaseAmount
      expiryInMonths
      daysOfPurchase
      activated
      message
    }
    removeUpgradedCustomerSlot {
      start
      end
      _id
    }
  }
}`
export const UpdatePremiumCustomerConfig = `mutation UpdatePremiumCustomerConfig($purchaseAmount: Float, $expiryInMonths: Int, $daysOfPurchase: Int, $activated: Boolean, $message: String) {
  updatePremiumCustomerConfig(purchaseAmount: $purchaseAmount, expiryInMonths: $expiryInMonths, daysOfPurchase: $daysOfPurchase, activated: $activated, message: $message) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }

    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
    premiumCustomerConfig {
      purchaseAmount
      expiryInMonths
      daysOfPurchase
      activated
      message
    }
    removeUpgradedCustomerSlot {
      start
      end
      _id
    }
  }
}`

export const UpdateLandingPage = `
mutation UpdateLandingPageDisplay($displayInput: DisplayInput!) {
  updateLandingPageDisplay(displayInput: $displayInput) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
      contact_address
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
  }
}`
export const AddLandingPage = `
mutation AddLandingPageDisplay($landingPageInput: LandingPageInput) {
  addLandingPageDisplay(landingPageInput: $landingPageInput)
}`
export const AddBanner = `
mutation AddBanner($bannerInput: BannerInput) {
  addBanner(bannerInput: $bannerInput) {
    _id
    name
    contents {
      image
      action
    }
    isActive
    updatedAt
    storeId
  }
}
`
export const AddBulkCreditToNestCash = `
mutation AddBulkCreditToNestCash($bulKCreditInput: BulKCreditInput) {
  addBulkCreditToNestCash(bulKCreditInput: $bulKCreditInput) {
    applied
    message
    phone
  }
}`
export const AdminAssignWorkerToOrder = `
mutation AdminAssignWorkerToOrder($orderId: String, $workerEmail: String) {
  adminAssignWorkerToOrder(orderId: $orderId, workerEmail: $workerEmail) {
    orderId
    workerEmail
    status
  }
}`

export const UpdateDeliveryBoundsAndLocation = `
mutation UpdateDeliveryBoundsAndLocation($storeId: String!, $bounds: [[[Float!]]], $location: CoordinatesInput!) {
  updateDeliveryBoundsAndLocation(storeId: $storeId, bounds: $bounds, location: $location) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
  }
}`

export const DeleteTimeSlotForRiderConfigLS = `
mutation DeleteTimeSlotForRiderConfigLS($id: String) {
  deleteTimeSlotForRiderConfigLS(_id: $id) {
    expansionRatio
    thresholdFactor
    timeSlots {
      start
      _id
    }
  }
}`
export const AddRiderAssignTimeLS = `
mutation AddRiderAssignTimeLS($start: String!) {
  addRiderAssignTimeLS(start: $start) {
    expansionRatio
    thresholdFactor
    timeSlots {
      start
      _id
    }
    
  }
}`




export const UpdateAdminPassword = `
mutation UpdateAdminPassword($password: String!, $adminId: String) {
  updateAdminPassword(password: $password, adminId: $adminId) {
    _id
    name
    email
    phone
    userType
    isActive
    storeId
    storeName
  }
}
`

export const CreateStock = `
mutation CreateStock($stockInput: StockInput!) {
  createStock(stockInput: $stockInput) {
    _id
    storeId
    quantity
    name
  }
}

`

export const UpdateSKUPriceByCustomerType = `
mutation UpdateSKUPriceByCustomerType($customerType: Int!, $skuId: String, $price: Float) {
  updateSKUPriceByCustomerType(customerType: $customerType, skuId: $skuId, price: $price) {
    _id
  }
}`
export const ApproveReplenishRequest = `
mutation ApproveReplenishRequest($replenishRequestApprove: ReplenishRequestApprove!) {
  approveReplenishRequest(replenishRequestApprove: $replenishRequestApprove) {
    statusString
    _id
    localStore
    localStoreName
    centralStore
    centralStoreName
    requestedItems {
      productId
      skuId
      stockId
      requestedQty
      approvedQty
      receivedQty
      Stock {
        _id
        storeId
        quantity
        name
      }
      requestedItemStates {
        requestedItemStatus
        requestedItemStatusString
        reason
        reasonString
      }
    }
    requestedByEmail
    requestedBy
    approvedByEmail
    approvedBy
    receivedByEmail
    receivedBy
    requestedDate
    approvedDate
    sendDate
    receivedDate
    requestStates {
      type
      requestStatus
      requestStatusString
    }
    status
    itemCount {
      requestedCount
      approvedCount
      receivedCount
    }
    comments
  }
}`


export const ReceiveReplenishRequest = `
mutation ReceiveReplenishRequest($replenishRequestReceive: ReplenishRequestReceive!) {
  receiveReplenishRequest(replenishRequestReceive: $replenishRequestReceive) {
    _id
    approvedBy
    approvedByEmail
    approvedDate
    centralStore
    centralStoreName
    itemCount {
      approvedCount
      receivedCount
      requestedCount
    }
    localStore
    localStoreName
    receivedBy
    receivedByEmail
    receivedDate
    requestStates {
      requestStatus
      requestStatusString
      type
    }
    requestedBy
    requestedByEmail
    requestedDate
    requestedItems {
      Stock {
        _id
        name
        quantity
        storeId
      }
      approvedQty
      productId
      receivedQty
      requestedItemStates {
        reason
        reasonString
        requestedItemStatus
        requestedItemStatusString
      }
      requestedQty
      skuId
      stockId
    }
    sendDate
    status
    statusString
  }
}`


export const InitiateReplenishRequest = `
mutation InitiateReplenishRequest($replenishRequestInput: ReplenishRequestInput!) {
  initiateReplenishRequest(replenishRequestInput: $replenishRequestInput) {
    _id
    approvedBy
    approvedByEmail
    centralStore
    approvedDate
    centralStoreName
    itemCount {
      approvedCount
      receivedCount
      requestedCount
    }
    localStore
    localStoreName
    receivedBy
    receivedByEmail
    receivedDate
    requestStates {
      requestStatus
      requestStatusString
      type
    }
    requestedBy
    requestedByEmail
    requestedDate
    requestedItems {
      Stock {
        _id
        name
        quantity
        storeId
      }
      approvedQty
      productId
      receivedQty
      requestedItemStates {
        reason
        reasonString
        requestedItemStatus
        requestedItemStatusString
      }
      requestedQty
      skuId
      stockId
    }
    sendDate
    status
    statusString
  }
}`
export const updateskudetails = `

mutation UpdateSKUDetails($skuUpdate: SKUUpdate!) {
  updateSKUDetails(skuUpdate: $skuUpdate) {
    Stock {
      _id
      name
      storeId
      quantity
    }
    _id
    barcode
    discount
    isActive
    localStocks {
      quantity
      replenishQty
      skuId
      storeId
    }
    measure
    minimumQty
    productId
    mrp
  
    stockId
    stockUnits
    storeId
    unit
  }
}
`

export const updateskuquantity = `
mutation UpdateSKUQuantity($skuId: String!, $storeId: String!, $productId: String!, $quantity: Float!) {
  updateSKUQuantity(skuId: $skuId, storeId: $storeId, productId: $productId, quantity: $quantity)
}
`
export const createlsadmin = `
mutation CreateLSAdmin($lsAdminInput: LSAdminInput!) {
  createLSAdmin(lsAdminInput: $lsAdminInput) {
    _id
    email
    createdBy
    isActive
    jwtToken
    phone
    name
    storeId
    storeName
    userType
  }
}`
export const deletelocalstore = `
mutation DeleteLocalStore($storeId: String!) {
  deleteLocalStore(storeId: $storeId) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
    }
    deliveryBounds {
      coordinates
    }
  }
}`
export const deleteproducts = `
mutation DeleteProduct($productId: String!) {
  deleteProduct(productId: $productId)
}
`
export const createcentralstore = `mutation Mutation($centralStoreInput: CentralStoreInput!) {
  createCentralStore(centralStoreInput: $centralStoreInput) {
    city
    name
  }
}`
export const createcatogery = `
mutation CreateCategory($categoryInput: CategoryInput!) {
  createCategory(categoryInput: $categoryInput) {
    _id
    name
    isActive
    images
    subcategories {
      _id
      name
      isActive
      images
      categoryId
    }
  }
}
`


export const createSubCategory = `
mutation CreateSubCategory($subCategoryInput: SubCategoryInput!) {
  createSubCategory(subCategoryInput: $subCategoryInput) {
    _id
    categoryId
    images
    isActive
    name
  }
}`
export const createcsadmin = `mutation Mutation($cSAdminInput: CSAdminInput!) {
  createCSAdmin(cSAdminInput: $cSAdminInput) {
    _id
    createdBy
    email
    isActive
    jwtToken
    name
    phone
    storeId
    storeName
    userType
  }
}`

export const createproduct = `
mutation CreateProduct($productInput: ProductInput!) {
  createProduct(productInput: $productInput) {
    _id
    name
    description
    aliases
    images
    defaultImage
    isActive
    category {
      _id
      name
      isActive
      images
      subcategories {
        _id
        name
        description
        isActive
        images
        categoryId
      }
      description
      
    }
    catalog {
      _id
      name
      description
      isActive
      parcelable
    }
    sortOrder
    hsn
    isNewProduct
    displayName
    skus {
      _id
      storeId
      productId
      discount
      barcode
      isActive
      mrp
      prices {
        customerType
        sellingPrice
      }
      unit
      measure
      minimumQty
      stockId
      stockUnits
      Stock {
        _id
        storeId
        quantity
        name
      }
      localStocks {
        storeId
        skuId
        quantity
        replenishQty
        defaultLSQty
      }
    }
  }
}
`

export const createcatlog = `mutation CreateCatalog($catalogInput: CatalogInput!) {
  createCatalog(catalogInput: $catalogInput) {
    _id
    description
    isActive
    name
    parcelable
  }
}`

//worker

export const updateworkerdata = `
mutation UpdateWorkerPassword($email: String!, $password: String!) {
  updateWorkerPassword(email: $email, password: $password)
}`
export const workeravailable = `mutation SetAvailable($available: Boolean) {
  setAvailable(available: $available) {
    storeName
    workerType
    storeId
  }
}`


export const modifyorder = `
mutation ModifyOrder($replaceOrder: ReplaceOrder!) {
  modifyOrder(replaceOrder: $replaceOrder) {
    _id
    items {
      productId
      skuId
      name
      description
      image
      quantity
      mrp
    
      status
    }
    customerId
    paymentStatus
    paymentMethod
    orderStatus
    paidAmount
    orderAmount
    orderDate
    deliveryDate
    billDetails {
      itemsTotal
      deliveryCharge
      couponDiscount
      grandTotal
      totalSaving
      handlingCharge
      itemSavings
      itemsMrp
    }
    deliveryAddress {
      _id
      location {
        coordinates
      }
      deliveryAddress
      details
      label
      selected
      isActive
    }
    onlinePaymentStatus
  }
}`
export const wokerOrderstatusUpdate = `
mutation UpdateWorkerOrderStatus($orderId: String!, $status: Int!) {
  updateWorkerOrderStatus(orderId: $orderId, status: $status)
}`
export const workerlogin = `
mutation WorkerLogin($email: String!, $password: String!) {
  workerLogin(email: $email, password: $password) {
    _id
    name
    email
    phone
    workerType
    isActive
    jwtToken
    storeId
    storeName
  }
}`

export const createworker = `
mutation CreateWorker($workerInput: WorkerInput!) {
  createWorker(workerInput: $workerInput) {
    _id
    name
    email
    phone
    workerType
    isActive
    jwtToken
    storeId
    storeName
  }
}`
export const adminlogout = `
mutation Mutation {
  logoutAdmin
}`

export const workerlogout = `
mutation Mutation {
  logoutWorker
}`

// central store  ADMIN
export const updateStock = `mutation UpdateStockQuantity($stockId: String!, $storeId: String!, $quantity: Float!) {
  updateStockQuantity(stockId: $stockId, storeId: $storeId, quantity: $quantity)
}
`
//  Local store 
export const updatelocalstorequantity = `
mutation UpdateQuantityForLocalStore($skuId: String!, $localStoreId: String!, $productId: String!, $quantity: Float!) {
  updateQuantityForLocalStore(skuId: $skuId, localStoreId: $localStoreId, productId: $productId, quantity: $quantity)
}`
//CS ADMIN
export const createsku = `mutation CreateSKU($skuInput: SKUInput!) {
  createSKU(skuInput: $skuInput) {
    _id
    barcode
    discount
    isActive
    localStocks {
      quantity
      skuId
      storeId
      replenishQty
    }
    measure
    minimumQty
    mrp
  
    productId
    stockId
    stockUnits
    storeId
    unit
  }
}`
export const createlocalstore = `mutation Mutation($localStoreInput: LocalStoreInput!) {
  createLocalStore(localStoreInput: $localStoreInput) {
    _id
    address
    admins {
      _id
      email
      name
      phone
      storeId
      storeName
    }
    city
    code
    deliveryBounds {
      coordinates
    }
    location {
      coordinates
    }
    name
    type
  }
}`
export const createlocaladmin = `mutation CreateLSAdmin($lsAdminInput: LSAdminInput!) {
  createLSAdmin(lsAdminInput: $lsAdminInput) {
    _id
    createdBy
    email
    isActive
    name
    phone
    storeId
    storeName
    userType
  }
}`
// update section
//csADmin
export const updatelocaladmin = `mutation UpdateLSAdmin($adminId: String!, $lsAdminUpdateInput: CSAdminUpdateInput!) {
  updateLSAdmin(adminId: $adminId, LSAdminUpdateInput: $lsAdminUpdateInput) {
    _id
    email
    createdBy
    name
    phone
    storeId
    storeName
  }
}`
export const updatelocalstore = 
`mutation UpdateLocalStore($storeId: String!, $localStoreUpdateInput: LocalStoreUpdateInput!) {
  updateLocalStore(storeId: $storeId, localStoreUpdateInput: $localStoreUpdateInput) {
    _id
    name
    type
    code
    address
    city
    location {
      coordinates
    }
    admins {
      _id
      name
      email
      phone
      userType
      isActive
      jwtToken
      createdBy
      storeId
      storeName
      contact_address
    }
    deliveryBounds {
      coordinates
    }
    landingPage {
      _id
      landingPageDisplays {
        deviceType
        _id
        displays {
          _id
          displayType
          enabled
          rows
          columns
          displayPosition
        }
      }
    }
    landingPageDisplayConfigs {
      _id
      displayName
      displayType
    }
    premiumCustomerConfig {
      purchaseAmount
      expiryInMonths
      daysOfPurchase
      activated
      message
    }
    removeUpgradedCustomerSlot {
      start
      end
      _id
    }
    customerDeliverySlot {
      name
      leadTime
      timeSlot {
        start
        end
        _id
      }
    }
   
    isActive
  }
}`

export const updatesubcategory = `
mutation UpdateSubCategory($subCategoryUpdate: SubCategoryUpdate!) {
  updateSubCategory(subCategoryUpdate: $subCategoryUpdate) {
    _id
    categoryId
    images
    isActive
    name
    description
  }
}`
export const updatecategory = `
mutation UpdateCategory($categoryUpdate: CategoryUpdate!) {
  updateCategory(categoryUpdate: $categoryUpdate) {
    _id
    name
    isActive
    images
    subcategories {
      _id
      name
      description
      isActive
      images
      categoryId
    }
    description
  }
}`

export const updatecatlog = `mutation UpdateCatalog($catalogUpdate: CatalogUpdate!) {
  updateCatalog(catalogUpdate: $catalogUpdate) {
    _id
    description
    isActive
    name
    parcelable
  }
}`

export const updatecentralstore = `mutation UpdateCentralStore($storeId: String!, $centralStoreUpdateInput: CentralStoreUpdateInput!) {
  updateCentralStore(storeId: $storeId, centralStoreUpdateInput: $centralStoreUpdateInput) {
    _id
    address
    admins {
      _id
      createdBy
      isActive
      email
      jwtToken
      name
      phone
      storeId
      storeName
      userType
    }
    city
    code
    deliveryBounds {
      coordinates
    }
    location {
      coordinates
    }
    name
    type
  }
}`

export const updatecsadmin = `mutation UpdateCSAdmin($adminId: String!, $cSAdminUpdateInput: CSAdminUpdateInput!) {
  updateCSAdmin(adminId: $adminId, cSAdminUpdateInput: $cSAdminUpdateInput) {
    _id
    createdBy
    email
    jwtToken
    isActive
    name
    phone
    storeId
    userType
    storeName
  }
}`
//Delete Section 
export const deletecentralstore = `mutation DeleteCentralStore($storeId: String!) {
  deleteCentralStore(storeId: $storeId) {
    _id
    admins {
      createdBy
      _id
      email
      isActive
      jwtToken
      name
      phone
      storeId
      storeName
      userType
    }
    address
    city
    code
    deliveryBounds {
      coordinates
    }
    location {
      coordinates
    }
    name
    type
  }
}`
export const deletecsadmin = `mutation DeleteCSAdmin($adminId: String!) {
  deleteCSAdmin(adminId: $adminId) {
    name
    storeId
  }
}`
export const deletecategory = `mutation DeleteCategory($categoryId: String!) {
  deleteCategory(categoryId: $categoryId)
}`

export const AdminLogin = `mutation AdminLogin($email: String!, $password: String!) {
  adminLogin(email: $email, password: $password) {
    _id
    jwtToken
    name
    phone
    storeId
    storeName
    userType
    email
    isActive
    createdBy
  }
}`




export const updateOrderStatus = `mutation UpdateOrderStatus($id:String!,$status:String!,$reason:String){
  updateOrderStatus(id:$id,status:$status,reason:$reason){
    _id
    orderStatus
  }
}
`
export const updateStatus = `mutation UpdateStatus($id:String!,$orderStatus:String!){
  updateStatus(id:$id,orderStatus:$orderStatus){
    _id
    orderStatus
  }
}
`



export const UpdateProduct = `mutation UpdateProduct($productInput: ProductInput!) {
  updateProduct(productInput: $productInput) {
    _id
    name
    description
    aliases
    images
    defaultImage
    isActive
    category {
      _id
      name
      isActive
      images
      subcategories {
        _id
        name
        description
        isActive
        images
        categoryId
      }
      description
    }
    catalog {
      _id
      name
      description
      isActive
      parcelable
    }
    sortOrder
    hsn
    isNewProduct
    displayName
    skus {
      _id
      storeId
      productId
      discount
      barcode
      isActive
      mrp
      prices {
        customerType
        sellingPrice
      }
      unit
      measure
      minimumQty
      stockId
      stockUnits
      Stock {
        _id
        storeId
        quantity
        name
      }
      localStocks {
        storeId
        skuId
        quantity
        replenishQty
        defaultLSQty
      }
    }
  }
}`


export const updateDeliveryBoundsAndLocation = `mutation UPDATE_DELIVERY_BOUNDS_AND_LOCATION($id:ID!,$bounds:[[[Float!]]],$location:CoordinatesInput!){
  result :updateDeliveryBoundsAndLocation(id:$id,location:$location,bounds:$bounds){
    success
    message
    data{
      _id
      deliveryBounds{
        coordinates
      }
      location{
        coordinates
      }
    }
  }
}`

