import React, { useRef, useState, useEffect } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { UpdateProduct, createproduct, getallcategories, getcatlog, getsubcategory } from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Switch, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar, IconButton } from '@mui/material';
import useStyles from './styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useGlobalStyles from '../../utils/globalStyles';
import AWS from 'aws-sdk';
import { imageBaseUrl } from '../../utils/imageLink';
import S3 from 'aws-sdk/clients/s3';
import DeleteIcon from '@mui/icons-material/Delete';


const EDIT_VENDOR = gql`
  ${UpdateProduct}
`;
const GET_VENDORS = gql`
  ${getcatlog}
`;

const GET_CATALOG = gql`
  ${getcatlog}
`;

const GET_SUBCATEGORY = gql`
  ${getsubcategory}
`;
const GET_ALLCATEGORIES = gql`
  ${getallcategories}
`;
const CREATE_PRODUCT = gql`
  ${createproduct}
`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function AddProduct(props) {
  const { onClose } = props;
  console.log(props.vendor ,"inside add dataa")
  const formRef = useRef();
  const fileInputRef = useRef(null)
  const mutation = props.vendor ? EDIT_VENDOR : CREATE_PRODUCT;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  // State to store uploaded images
  const [selectedCategory, setSelectedCategory] = useState(props.vendor ? props.vendor.storeName : null);
  const [selectedCatalog, setSelectedCatalog] = useState(props.vendor ? props.vendor.catalog._id : null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(props.vendor ? props.vendor.category.subcategories[0] ? props.vendor.category.subcategories[0]._id : null : null);

  const [formErrors, setFormErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageDefault, setPreviewImageDefault] = useState(null);
  const [previewImageOffer, setPreviewImageOffer] = useState(null);
  const [imageName, setImageName] = useState(null)
  const [imageNameDefault, setImageNameDefault] = useState(null)
  
  const [imageNameOffer, setImageNameOffer] = useState(null)
  const [file, setFile] = useState(null);
  const [fileDefault, setFileDefault] = useState(null);
  const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
  const [fileOffer, setFileOffer] = useState(null);
  const [uploading, setUploading] = useState(false)
  const [uploadingDefault, setUploadingDefault] = useState(false)
  const [uploadingOffer, setUploadingOffer] = useState(false)
  const [images, setImages] = useState(props.vendor ? props.vendor.images : []);
  const [imagesDefault, setImagesDefault] = useState(props.vendor ? props.vendor.defaultImage : []);
  const [imagesOffer, setImagesOffer] = useState(props.vendor ? props.vendor.offerImage : []);
  
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : '',
    name: props.vendor ? props.vendor.name : '',
    description: props.vendor ? props.vendor.description : '',
    catalogId: props.vendor ? props.vendor.catalog._id : '',
    category: props.vendor ? props.vendor.category._id : '',
    defaultImage: props.vendor ? props.vendor.defaultImage : '',
    displayName: props.vendor ? props.vendor.displayName : '',
    hsn: props.vendor ? props.vendor.hsn : '',
    sortOrder:props.vendor ? props.vendor.sortOrder:null,
    catalogDefault: props.vendor ? props.vendor.catalogDefault : false,
    images: props.vendor ? props.vendor.images : [],
    isNewProduct: props.vendor ? props.vendor.isNewProduct : true,
    offerEnabled: props.vendor ? props.vendor.offerEnabled : false,
    measure: props.vendor ? props.vendor.measure : '',
    subcategory: props.vendor ? props.vendor.category.subcategories[0]? props.vendor.category.subcategories[0]._id : '' : '',
    aliases: props.vendor ? props.vendor.aliases : '',
    igst: props.vendor ? props.vendor.IGST : 0,
    sgst: props.vendor ? props.vendor.SGST : 0,
    cgst: props.vendor ? props.vendor.CGST : 0,
    isActive: props.vendor ? props.vendor.isActive : false,
    imagesOffer: props.vendor ? props.vendor.imagesOffer :''
  });
console.log(formData , 'formData' )
  const [openSnackk, setopenSnackk] = useState(false);


  const { data, refetch: refetchCentralStore } = useQuery(GET_ALLCATEGORIES);
  // const options = data ? data.getCategories || [] : [];


  const { data: Catalog } = useQuery(GET_CATALOG);
  const optionsCatalog = Catalog ? Catalog.getCatalogs || [] : [];
  const { data: SubCategory, refetch: refetchSubCategory } = useQuery(GET_SUBCATEGORY, {
    variables: {
      categoryId: formData.category
    }
  });
  const optionsSubCategory = SubCategory ? SubCategory.getSubCategories || [] : [];

  useEffect(() => {
    if (data && props.vendor && props.vendor.category._id) {
      const selectedStore = data.getCategories.find(
        (store) => store._id === props.vendor.category._id
      );
      setSelectedCategory(selectedStore || null);
    }

  }, [data, props.vendor]);

  useEffect(() => {
    if (Catalog && props.vendor && props.vendor.catalog._id) {
      const selectedStore = Catalog.getCatalogs.find(
        (store) => store._id === props.vendor.catalog._id
      );
      setSelectedCatalog(selectedStore || null);
    }

  }, [Catalog, props.vendor]);

  useEffect(() => {
    if (SubCategory && props.vendor && props.vendor.category.subcategories[0]?props.vendor.category.subcategories[0]._id : null) {
      const selectedStore = SubCategory.getSubCategories.find(
        (store) => store._id === props.vendor.category.subcategories[0]._id
      );
      setSelectedSubCategory(selectedStore || null);
    }

  }, [SubCategory, props.vendor]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetchCentralStore();
        // await refetchCatalog();
      } catch (error) {
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // Other code
  }, [data]);

  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'video/mp4',
    'video/quicktime',
    'audio/mpeg',
    'audio/wav',
    // Add more supported types as needed
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name)
    setImageNameDefault(selectedFile.name)
    if (allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type. Only images and PDFs are allowed.');
    }
  };
  const handleFileChangeDefault = (event) => {
    const selectedFile = event.target.files[0];
    setImageNameDefault(selectedFile.name)
    if (allowedTypes.includes(selectedFile.type)) {
      setFileDefault(selectedFile);

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImageDefault(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type. Only images and PDFs are allowed.');
    }
  };
  const handleFileChangeOfferImage = (event) => {
    const selectedFile = event.target.files[0];
    setImageNameOffer(selectedFile.name)
    if (allowedTypes.includes(selectedFile.type)) {
      setFileOffer(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImageOffer(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type. Only images and PDFs are allowed.');
    }
  };

  const uploadFile = async () => {
    setUploading(true);
    const S3_BUCKET = "nativenest"; // Replace with your bucket name
    const REGION = "ap-south-1"; // Correct region code
  
    AWS.config.update({
      accessKeyId: "AKIAVRUVQVTHJR36HMGG",
      secretAccessKey: "EMAgAmoPdPgK9rLyqIEUva3mjZGwlJyl2de1f1UU",
    });
  
    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
  
    const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
  console.log(folderStructure ,"folderStructure")
    // Function to determine the content type based on file extension
    const getContentType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      const contentTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more content types as needed
      };
      return contentTypes[extension] || 'application/octet-stream';
    };
  
    const contentType = getContentType(file.name);
  
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderStructure}/${file.name}`, // File path in S3 bucket
      Body: file,
      ContentType: contentType, // Add ContentType here
      // ACL: 'public-read' // Set ACL to public-read
    };
  
    try {
      const upload = await s3.putObject(params).promise();
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderStructure}/${file.name}`;
console.log(upload ,"upload" , imageUrl ,"imageURl")
      setUploading(false);
      alert("File uploaded successfully.");
      // Update formData with the uploaded image URL
      setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, imageUrl],
      }));
    } catch (error) {
      setUploading(false);
      alert("Error uploading file: " + error.message); // Inform user about the error
    }
  };


  const uploadFileDefault = async () => {
    setUploadingDefault(true);
    const S3_BUCKET = "nativenest"; // Replace with your bucket name
    const REGION = "ap-south-1"; // Correct region code

    AWS.config.update({
      accessKeyId: "AKIAVRUVQVTHJR36HMGG",
      secretAccessKey: "EMAgAmoPdPgK9rLyqIEUva3mjZGwlJyl2de1f1UU",
    });
    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
    const getContentType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      const contentTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more content types as needed
      };
      return contentTypes[extension] || 'application/octet-stream';
    };
    const contentType = getContentType(fileDefault.name);
  
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderStructure}/${fileDefault.name}`, // File path in S3 bucket
      Body: file,
      ContentType: contentType, // Add ContentType here
      // ACL: 'public-read' // Set ACL to public-read
    };
    try {
      const upload = await s3.putObject(params).promise();
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderStructure}/${fileDefault.name}`;
      setUploadingDefault(false);
      alert("File uploaded successfully.");
      // Update formData with the uploaded image URL
      setFormData((prevState) => ({
        ...prevState,
        defaultImage: imageUrl,
      }));
    } catch (error) {
      setUploading(false);// Inform user about the error
    }
  };
  const uploadFileOfferImage = async () => {
    setUploadingOffer(true);
    const S3_BUCKET = "nativenest"; // Replace with your bucket name
    const REGION = "ap-south-1"; // Correct region code

    AWS.config.update({
      accessKeyId: "AKIAVRUVQVTHJR36HMGG",
      secretAccessKey: "EMAgAmoPdPgK9rLyqIEUva3mjZGwlJyl2de1f1UU",
    });
    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const getContentType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      const contentTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more content types as needed
      };
      return contentTypes[extension] || 'application/octet-stream';
    };
      
    const contentType = getContentType(file.name);
  
    const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderStructure}/${fileOffer.name}`, // File path in S3 bucket
      Body: file,
      ContentType: contentType, // Add ContentType here
      // ACL: 'public-read' // Set ACL to public-read
    };
  
    try {
      const upload = await s3.putObject(params).promise();
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderStructure}/${fileOffer.name}`;
      setUploadingOffer(false);
      // Update formData with the uploaded image URL
      alert("File uploaded successfully.");
      setFormData((prevState) => ({
        ...prevState,
        imagesOffer: imageUrl,
      }));
    } catch (error) {
      setUploading(false);// Inform user about the error
    }
  };
  // IMAGE DELETE VERSION 


  const [categoryError, setCategoryError] = useState(false)
  const [subcategoryError, setSubCategoryError] = useState(false)
  const [catlogError, setCatelogError] = useState(false)
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   // Update form data state
  //   setFormData({ ...formData, [name]: value });
  //   if (formErrors[name]) {
  //     setFormErrors({ ...formErrors, [name]: '' });
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  console.log(name ,value , "testing" ,formData)
    if (['igst', 'cgst', 'sgst'].includes(name)) {
      console.log("comming here to test " ,value, "testing 1")
      // Regex to allow only positive numbers and decimals
      const regex = /^(?:[0-9]{1,2}(?:\.[0-9]{0,2})?|100(?:\.0{0,2})?)$/;
  
      // Update state only if input matches regex or is empty
      if (regex.test(value) ) {
        console.log("comming here to test " ,value, "testing 2")
        setFormData({ ...formData, [name]: value });
      }
    } else if (!['igst', 'cgst', 'sgst'].includes(name)) {
      // Default handling for other fields
      console.log("comming here to test " ,value, "testing 3")
      // setFormData({ ...formData, [name]: value });
    }
  
    // Clear errors if any
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };
  
  const validateFormData = () => {
    let errors = {};
    if (!formData.id) errors.id = 'Product ID is required';
    if (!formData.name) errors.name = 'Product Name is required';
    if (!formData.description) errors.description = 'Description is required';
    if (!formData.catalogId || formData.catalogId === undefined || formData.catalogId === null) {
      setCatelogError(true)
    }
    if (!formData.category || formData.category === null || formData.category === undefined) {
      setCategoryError(true)
    }
    if (!formData.subcategory || formData.subcategory === undefined || formData.subcategory === null) {
      setSubCategoryError(true)
    }
    // if (!formData.defaultImage) errors.defaultImage = 'Default Image is required';
    if (!formData.hsn) errors.hsn = 'HSN is required';
    if (!formData.aliases) errors.aliases = 'ALIASES is required';
    if (!formData.displayName) errors.displayName = 'Display Name is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Product Updated Successfully') : t('Product Added Successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    props.refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)

  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_VENDORS }],
    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormErrors({});

    setFormData({
      name: '',
      description: '',
      catalogId: '',
      category: '',
      defaultImage: '',
      displayName: '',
      hsn: '',
      images: '',
      isNewProduct: '',
      measure: '',
      subcategory: '',
      aliases: '',
      catalogDefault: '',
    });
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleCategoryChange = (event, value) => {
    setSelectedCategory(value);
    setFormData({ ...formData, category: value._id });
    refetchSubCategory()
    if (formData.category !== undefined || formData.category !== null) {
      setCategoryError(false)
    }
  };
  const handleCatalogChange = (event, value) => {
    setSelectedCatalog(value);
    setFormData({ ...formData, catalogId: value._id });
    if (formData.catalogId !== undefined || formData.catalogId !== null) {
      setCatelogError(false)
    }

  };
  const handleSubCategoryChange = (event, value) => {
    setSelectedSubCategory(value);
    setFormData({ ...formData, subcategory: value._id });
    if (formData.subcategory !== undefined || formData.subcategory !== null) {
      setSubCategoryError(false)
    }

  };

  console.log(imageNameDefault ,"imageNameDefault")
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      // setOpenSnack(true);
      return;
    }
    if (true) {
      !props.vendor ?
        mutate({

          variables: {

            productInput: {

              _id: formData.id,
              name: formData.name,
              description: formData.description,
              catalogId: formData.catalogId,
              // catalogId:"65f00c5101f09196b3081dfe",
              category: formData.category,
              // category:"65f005dd083576fbee0b7823",
              subcategory: formData.subcategory,
              defaultImage:imageNameDefault?  imageBaseUrl + `${folderStructure}/${imageNameDefault}`: '',
              displayName: formData.displayName,
              hsn: formData.hsn,
              images:imageName ? imageBaseUrl + `${folderStructure}/${imageName}` : '',
              isNewProduct: formData.isNewProduct,
              // measure: formData.measure,
              catalogDefault:formData.catalogDefault,
              offerEnabled: formData.offerEnabled,
              offerImage: imageNameOffer?  imageBaseUrl + `${folderStructure}/${imageNameOffer}` : '',
              isActive: formData.isActive,
              SGST: parseFloat(formData.sgst),
              CGST: parseFloat(formData.cgst),
              IGST: parseFloat(formData.igst),
              aliases: [formData.aliases],
              sortOrder: formData.sortOrder
            }
          }
          
        }
      ) :
        mutate({
          variables: {

            productInput: {

              _id: formData.id,
              name: formData.name,
              description: formData.description,
              catalogId: formData.catalogId,
              // catalogId:"65f00c5101f09196b3081dfe",
              category: formData.category,
              // category:"65f005dd083576fbee0b7823",
              defaultImage: imageNameDefault ? imageBaseUrl + `${folderStructure}/${imageNameDefault}` : formData.defaultImage,
              displayName: formData.displayName,
              hsn: formData.hsn,
              images: imageName ? imageBaseUrl + `${folderStructure}/${imageName}` : formData.images, // Check if this is how your backend expects images
              catalogDefault:formData.catalogDefault,
              isNewProduct: formData.isNewProduct,
              measure: formData.measure,
              subcategory: formData.subcategory,
              aliases: formData.aliases,

              sortOrder: formData.sortOrder,
              offerEnabled: formData.offerEnabled,
              offerImage: imageNameOffer ? imageBaseUrl + `${folderStructure}/${imageNameOffer}` : formData.offerImage,
              isActive: formData.isActive,
              SGST: parseFloat(formData.sgst),
              CGST: parseFloat(formData.cgst),
              IGST: parseFloat(formData.igst),
            }
          }
          
        }
      );

      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 4000);
    }
  };

  const handleRemoveImage = (index) => {
    // setPreviewImage(null); 
    // images = []
    // const updatedImages = [...formData.images]; // Copy the current images array
    // updatedImages.splice(index, 1); // Remove the image at the given index
    // setFormData({ ...formData, images: updatedImages }); 

    setPreviewImage(null); 
    const updatedImages = [...images]; // Copy the current images array from state
    updatedImages.splice(index, 1); // Remove the image at the given index
    setImages(updatedImages); // Update the images state
    setFormData({ ...formData, images: updatedImages });
    // setPreviewImage(null)
    // images= []
    // const updatedImages = [...formData.images]; // Copy the current images array
    // updatedImages.splice(index, 1); // Remove the image at the given index
    // setFormData({ ...formData, images: '' }); // Update the state with the modified array
  };
  
  const handleRemoveDefaultImage = (index) => {

    setPreviewImageDefault(null);
    setImagesDefault([]); // Clear the state of the default image
    setFormData({ ...formData, defaultImage: '' });
    // setPreviewImageDefault(null);
    // setFormData({ ...formData, defaultImage: '' }); // Clear the default image
    // setPreviewImageDefault(null)
    // imagesDefault=[]
    // const updatedImagesDefault = [...formData.defaultImage]; // Copy the current default images array
    // updatedImagesDefault.splice(index, 1); // Remove the default image at the given index
    // setFormData({ ...formData, defaultImage: '' }); // Update the state with the modified array
  };

  const handleRemoveOfferImage= (index) => {
    setPreviewImageOffer(null);
    setImagesOffer([]);
    setFormData({ ...formData, offerImage: '' }); // Clear the offer image
    // imagesOffer=[]
    // setPreviewImageOffer(null)
    // setFormData({ ...formData, defaultImage: '' });

  };
  
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>

      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('Add Product ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>

          <Grid container spacing={2}>
            {!props.vendor ?
              <>

<Grid item xs={12} sm={6}>
  {(props.vendor && previewImage == null) ? (
    <>
      {formData.images.map((image, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
          <IconButton onClick={() => handleRemoveImage(previewImage)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
        </div>
      ))}
    </>
  ) : (
    <>
      {previewImage && (
        <div>
          <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
          <IconButton onClick={() => handleRemoveImage(previewImage)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
        </div>
        
      )}
    </>
  )}

  <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
    Choose Image
    <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
  </Button>
  <Button onClick={uploadFile} disabled={!file} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
    {uploading ? 'Uploading...' : 'Upload File'}
  </Button>
</Grid>

{/* Default images section */}
<Grid item xs={12} sm={6}>
  {(props.vendor && previewImageDefault == null) ? (
    <>
      {formData.defaultImage.map((image, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
          <IconButton onClick={() => handleRemoveDefaultImage(index)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
        </div>
      ))}
    </>
  ) : (
    <>
      {previewImageDefault && (
        <div>
          <img src={previewImageDefault} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
          <IconButton onClick={() => handleRemoveDefaultImage(previewImageDefault)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
        </div>
      )}
    </>
  )}

  <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
    Choose Default Image
    <input type="file" onChange={handleFileChangeDefault} ref={fileInputRef} style={{ display: 'none' }} />
  </Button>
  <Button onClick={uploadFileDefault} disabled={!fileDefault} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
    {uploadingDefault ? 'Uploading...' : ' Upload Default Image'}
  </Button>
</Grid>


                <Grid item xs={12} sm={6}>
                  <TextField name="id" label="Product ID" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.id}
                    error={Boolean(formErrors.id)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="name" label="Product Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} helperText={formErrors.name}
                    error={Boolean(formErrors.name)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="displayName" label="Display Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} helperText={formErrors.displayName}
                    error={Boolean(formErrors.displayName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="description" label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} helperText={formErrors.description}
                    error={Boolean(formErrors.description)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.isNewProduct ? 'gray' : 'red' }}>
                      New Product
                    </Typography>
                    <Switch name="isNewProduct" checked={formData.isNewProduct} onChange={handleSwitchChange} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                      Active
                    </Typography>
                    <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.catalogDefault ? 'gray' : 'red' }}>
                    Default catalog
                    </Typography>
                    <Switch name="catalogDefault" checked={formData.catalogDefault} onChange={handleSwitchChange} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="defaultImage" label="  Default Image" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={data && data.getCategories ? data.getCategories : []}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        error={categoryError}
                        helperText={categoryError ? 'Please select a category' : ''}

                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />

                    )}
                    PaperComponent={CustomPaperComponent}
                    helperText={formErrors.category}
                    error={Boolean(formErrors.category)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={SubCategory ?SubCategory.getSubCategories : []|| []}
                    options={SubCategory && SubCategory.getSubCategories ? SubCategory.getSubCategories : []}
                    getOptionLabel={(option) => option.name}
                    onChange={handleSubCategoryChange}
                    value={selectedSubCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Category"
                        error={subcategoryError}
                        helperText={subcategoryError ? 'Please select a sub category' : ''}

                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />

                    )}
                    helperText={formErrors.subcategory}
                    error={Boolean(formErrors.subcategory)}
                    // inputProps={{ style: inputTextStyle }}
                    PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={Catalog ?Catalog.getCatalogs : []|| []}
                    options={Catalog && Catalog.getCatalogs ? Catalog.getCatalogs : []}
                    getOptionLabel={(option) => option.name}
                    onChange={handleCatalogChange}
                    value={selectedCatalog}
                    helperText={formErrors.catalogId}
                    error={Boolean(formErrors.catalogId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Catlog"
                        error={catlogError}
                        helperText={catlogError ? 'Please select a catalog' : ''}
                        variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }}
                      />
                    )}
                    // inputProps={{ style: inputTextStyle }}
                    PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="sortOrder" label="Sort Order" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.sortOrder}
                    error={Boolean(formErrors.sortOrder)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="hsn" label="HSN" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.hsn}
                    error={Boolean(formErrors.hsn)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField name="aliases" label="Aliases" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.aliases}
                    error={Boolean(formErrors.aliases)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="igst" label="IGST" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.hsn}
                    error={Boolean(formErrors.hsn)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="cgst" label="CGST" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.hsn}
                    error={Boolean(formErrors.hsn)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="sgst" label="SGST" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.hsn}
                    error={Boolean(formErrors.hsn)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.isNewProduct ? 'gray' : 'red' }}>
                      Offer Available
                    </Typography>
                    <Switch name="offerEnabled" checked={formData.offerEnabled} onChange={handleSwitchChange} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {(props.vendor && previewImageDefault == null) ?
                    <>
                      {imagesDefault.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          <IconButton onClick={() => handleRemoveOfferImage(index)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
                        </div>
                      ))}
                    </> :
                    <>
                      {previewImageOffer && (
                        <div>
                          <img src={previewImageOffer} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          <IconButton onClick={() => handleRemoveOfferImage(previewImageOffer)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
                        </div>
                      )}

                    </>

                  }


                  <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    Choose  Offer Image
                    <input type="file" onChange={handleFileChangeOfferImage} ref={fileInputRef} style={{ display: 'none' }} />
                  </Button>
                  <Button onClick={uploadFileOfferImage} disabled={!fileOffer} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    {uploadingOffer ? 'Uploading...' : ' Upload Offer Image'}
                  </Button>
                </Grid>

              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>
                  <Grid item xs={12} sm={6}>
                    {(props.vendor && previewImage == null) ?
                      <>
                        {formData.images && images.map((image, index) => (
                          <div key={index}>
                            <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                        
                            <IconButton onClick={() => handleRemoveImage(index)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>  </div>
                        ))}
                      </> :
                      <>
                        {previewImage && (
                          <div>
                            <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                         
                            <IconButton onClick={() => handleRemoveImage(previewImage)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
                          </div>
                        )}

                      </>

                    }


                    <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      Choose Image
                      <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                    </Button>
                    <Button onClick={uploadFile} disabled={!file} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      {uploading ? 'Uploading...' : 'Upload  File'}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {(props.vendor && previewImage == null) ?
                      <>
                        {imagesDefault && <div
                        >
                          <img src={imagesDefault} alt={`Uploaded `} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                       
                          <IconButton onClick={() => handleRemoveDefaultImage(imagesDefault)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton> </div>}
                      </> :
                      <>
                        {previewImage && (
                          <div>
                            <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                            <IconButton onClick={() => handleRemoveDefaultImage(previewImageDefault)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
                          </div>
                        )}

                      </>

                    }


                    <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      Choose  Default Image
                      <input type="file" onChange={handleFileChangeDefault} ref={fileInputRef} style={{ display: 'none' }} />
                    </Button>
                    <Button onClick={uploadFileDefault} disabled={!fileDefault} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      {uploadingDefault ? 'Uploading...' : ' Upload Default Image'}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="id" label="Product ID" variant="outlined" fullWidth onChange={handleInputChange} value={formData.id} inputProps={{ style: inputTextStyle }}
                      helperText={formErrors.id}
                      error={Boolean(formErrors.id)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Product Name" variant="outlined" value={formData.name} fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                      helperText={formErrors.name}
                      error={Boolean(formErrors.name)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="displayName"
                      helperText={formErrors.id}
                      error={Boolean(formErrors.id)}
                      value={formData.displayName} label="Display Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="description"
                      helperText={formErrors.id}
                      error={Boolean(formErrors.id)}
                      value={formData.description} label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.isNewProduct ? '#637BFE' : 'red' }}>
                        New Product
                      </Typography>
                      <Switch name="isNewProduct" checked={formData.isNewProduct} onChange={handleSwitchChange} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                        Active
                      </Typography>
                      <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.catalogDefault ? 'gray' : 'red' }}>
                   Default catalog
                      </Typography>
                      <Switch name="catalogDefault" checked={formData.catalogDefault} onChange={handleSwitchChange} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      // options={SubCategory ?SubCategory.getSubCategories : []|| []}
                      options={optionsSubCategory}
                      getOptionLabel={(option) => option.name}
                      onChange={handleSubCategoryChange}
                      value={selectedCategory !== null & selectedCategory !== undefined ? selectedCategory : ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select  Category"
                          error={categoryError}
                          helperText={categoryError ? 'Please select a category' : ''}

                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      // inputProps={{ style: inputTextStyle }}
                      PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                      helperText={formErrors.selectedCategory}
                      error={Boolean(formErrors.selectedCategory)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      // options={SubCategory ?SubCategory.getSubCategories : []|| []}
                      options={optionsSubCategory}
                      getOptionLabel={(option) => option.name}
                      onChange={handleSubCategoryChange}
                      value={selectedSubCategory !== null & selectedSubCategory !== undefined ? selectedSubCategory : ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Sub Category"
                          error={subcategoryError}
                          helperText={subcategoryError ? 'Please select a sub category' : ''}

                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      // inputProps={{ style: inputTextStyle }}
                      PaperComponent={CustomPaperComponent} // Use the custom paper component to style options
                      helperText={formErrors.selectedSubCategory}
                      error={Boolean(formErrors.selectedSubCategory)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete

                      disablePortal
                      id="combo-box-demo"
                      // options={data.getAllCentralStores|| []}
                      options={optionsCatalog}
                      getOptionLabel={(option) => option.name}
                      onChange={handleCatalogChange}
                      value={selectedCatalog}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Catalog"
                          error={catlogError}
                          helperText={catlogError ? 'Please select a catalog' : ''}
                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      // inputProps={{ style: inputTextStyle }}
                      PaperComponent={CustomPaperComponent} // Use the custom paper component to style options

                      helperText={formErrors.selectedCatalog}
                      error={Boolean(formErrors.selectedCatalog)}

                    />

                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="sortOrder"
                      helperText={formErrors.sortOrder}
                      error={Boolean(formErrors.sortOrder)}
                      value={formData.sortOrder} label="Sort Order" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField name="hsn"
                      helperText={formErrors.hsn}
                      error={Boolean(formErrors.hsn)}
                      value={formData.hsn} label="HSN" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField name="aliases"
                      helperText={formErrors.aliases}
                      error={Boolean(formErrors.aliases)}
                      value={formData.aliases} label="Aliases" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
  <TextField
    name="igst"
    label="IGST"
    variant="outlined"
    fullWidth
    onChange={handleInputChange}
    inputProps={{
      style: inputTextStyle,
      min: 0, // Minimum value
      max: 100,
      inputMode: 'decimal', // Mobile keyboard for decimals
    }}
    onKeyPress={(e) => {
      // Prevent invalid characters
      if (['-', '+', 'e'].includes(e.key)) {
        e.preventDefault();
      }
    }}
    helperText={formErrors.igst}
    error={Boolean(formErrors.igst)}
    value={formData.igst}
    type="text" // Text ensures flexibility for float handling
  />
</Grid> */}

<Grid item xs={12} sm={6}>
  <TextField
    name="igst"
    label="IGST "
    variant="outlined"
    fullWidth
    onChange={handleInputChange}
    inputProps={{
      style: inputTextStyle,
      inputMode: 'decimal', // Enables decimal keyboard on mobile
      pattern: "^[0-9]*\\.?[0-9]*$", // Browser-enforced pattern
    }}
    onKeyPress={(e) => {
      const invalidKeys = ['-', '+', 'e', 'E'];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
      }
    }}
    helperText={formErrors.igst}
    error={Boolean(formErrors.igst)}
    value={formData.igst}
    type="text"
  />
</Grid>

<Grid item xs={12} sm={6}>
  <TextField
    name="cgst"
    label="CGST "
    variant="outlined"
    fullWidth
    onChange={handleInputChange}
    inputProps={{
      style: inputTextStyle,
      inputMode: 'decimal',
      pattern: "^[0-9]*\\.?[0-9]*$",
    }}
    onKeyPress={(e) => {
      const invalidKeys = ['-', '+', 'e', 'E'];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
      }
    }}
    helperText={formErrors.cgst}
    error={Boolean(formErrors.cgst)}
    value={formData.cgst}
    type="text"
  />
</Grid>

<Grid item xs={12} sm={6}>
  <TextField
    name="sgst"
    label="SGST "
    variant="outlined"
    fullWidth
    onChange={handleInputChange}
    inputProps={{
      style: inputTextStyle,
      inputMode: 'decimal',
      pattern: "^[0-9]*\\.?[0-9]*$",
    }}
    onKeyPress={(e) => {
      const invalidKeys = ['-', '+', 'e', 'E'];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault();
      }
    }}
    helperText={formErrors.sgst}
    error={Boolean(formErrors.sgst)}
    value={formData.sgst}
    type="text"
  />
</Grid>



                  {/* <Grid item xs={12} sm={6}>
                    <TextField name="cgst" label="CGST" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                      helperText={formErrors.hsn}
                      error={Boolean(formErrors.hsn)}
                      type='number'
                      />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <TextField name="sgst" label="SGST" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                      helperText={formErrors.hsn}
                      error={Boolean(formErrors.hsn)}
                      type='number' />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.isNewProduct ? 'gray' : 'red' }}>
                        Offer Available
                      </Typography>
                      <Switch name="offerEnabled" checked={formData.offerEnabled} onChange={handleSwitchChange} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {(props.vendor && previewImageOffer == null) ?
                      <>

                        {imagesOffer && <div


                        >
                          <img src={imagesOffer} alt={`Uploaded`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          <IconButton onClick={() => handleRemoveOfferImage(imagesOffer)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton>
                        </div>}

                      </> :
                      <>
                        {previewImageOffer && (
                          <div>
                            <img src={previewImageOffer} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                          
                          
                            <IconButton onClick={() => handleRemoveOfferImage(previewImageOffer)}>
            <DeleteIcon color="error" /> {/* Display the DeleteIcon */}
          </IconButton></div>
                        )}

                      </>

                    }


                    <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      Choose  Offer Image
                      <input type="file" onChange={handleFileChangeOfferImage} ref={fileInputRef} style={{ display: 'none' }} />
                    </Button>
                    <Button onClick={uploadFileOfferImage} disabled={!fileOffer} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      {uploadingOffer ? 'Uploading...' : ' Upload Offer Image'}
                    </Button>
                  </Grid>
                </>
              )
            }
          </Grid>

        </DialogContent>





        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? 'Update Product' : 'Add Product'}
          </Button>

          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddProduct);
