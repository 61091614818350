export const customStyles = {
  header: {
    style: {
      fontSize: '17px',
      fontWeight: 'bold',
      minHeight: '40px',
      paddingLeft: '16px',
      paddingRight: '8px',
      borderRadius: '20px 20px 0 0',
      whiteSpace: 'nowrap' // Ensures header text doesn't wrap
    }
  },
  headRow: {
    style: {
      backgroundColor: '#637BFE',
      color: '#FFFFFF',
      fontSize: '16px',
      fontWeight: 'bold',
      minHeight: '40px',
      borderRadius: '0 0 20px 20px',
      borderBottom: 'none',
      alignItems: 'center',
      textAlign: 'center',
      whiteSpace: 'nowrap' // Ensures header row text doesn't wrap
    },
    denseStyle: {
      minHeight: '32px'
    }
  },
  subHeader: {
    style: {
      minHeight: '0px',
      marginTop: -14,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)'
    }
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white',
      fontWeight: 'bold',
      whiteSpace: 'nowrap', // Prevents header cell text from wrapping
      minWidth: '150px', // Ensures a minimum width for all header cells
      '&:nth-of-type(1)': { minWidth: '100px' }, // Specific width for the first column
      '&:nth-of-type(2)': { minWidth: '150px' } // Specific width for the second column
      // Add similar lines for other columns as needed
    }
  },
  rows: {
    style: {
      fontSize: '13px',
      fontFamily: [
        'Euclid Circular A',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(', '),
      fontWeight: 400,
      fontOpticalSizing: 'auto',
      fontStyle: 'normal',
      color: 'black',
      minHeight: '50px',
      textAlign: 'center',
      whiteSpace: 'normal', // Allows cell text to wrap
      wordWrap: 'break-word', // Breaks long words to fit within the cell
      wordBreak: 'break-word', // Break words at allowed break points to prevent overflow
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '0px'
      },

      '&:hover': {
        backgroundColor: '#f9fafc'
      }
    }
  },
  pagination: {
    style: {
      fontSize: '13px',
      minHeight: '50px',
      borderRadius: '0 0 20px 20px',
      textAlign: 'left'
    },
    pageButtonsStyle: {
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: 'red',
        fill: '#5A5858'
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#EBF1FE'
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#3E79F7'
      }
    }
  }
}

// Apply horizontal scrolling to the table container
const tableContainerStyle = {
  overflowX: 'auto' // Enables horizontal scrolling
}
